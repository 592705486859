import { IUser } from "entities/account";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_TRACK_KEYS } from "shared/config/interface";

export class Mixpanel {
    private static tracking_active: boolean = Boolean(process.env.REACT_APP_NODE_CONFIGURATION === "production");

    private static user: IUser = {} as IUser;

    static init(): void {
        if (!Mixpanel.tracking_active) return;

        const KEY = process.env.REACT_APP_MIXPANEL_KEY;

        if (!KEY) {
            console.error("Mixpanel key is missing");
            return;
        }

        mixpanel.init(KEY, {
            debug: false,
            track_pageview: false,
            persistence: "localStorage",
            ignore_dnt: true
        });
    }

    static set_user(user: IUser): void {
        Mixpanel.user = user;
    }

    static identify(): void {
        if (!Mixpanel.tracking_active) return;

        mixpanel.identify(Mixpanel.user.username);

        mixpanel.people.set({
            $name: Mixpanel.user.username,
            $email: Mixpanel.user.email,
            organization: Mixpanel.user.organization_current?.name
        });

        mixpanel.register({
            organization: Mixpanel.user.organization_current?.name,
            user_id: Mixpanel.user.id
        });
    }

    static track(track_key: MIXPANEL_TRACK_KEYS, props: any): void {
        if (!Mixpanel.tracking_active) return;
        mixpanel.track(track_key, props);
    }

    static reset(): void {
        if (!Mixpanel.tracking_active) return;
        mixpanel.reset();
        Mixpanel.user = {} as IUser;
    }
}
