/* eslint-disable react/no-array-index-key */
import "./table.scss";
import { IColumnTable } from "shared/config/interface";

interface ITableProps<T> {
    columns: IColumnTable<T>[];
    data: T[];
    onTdClick?: (item: T) => void;
}

const Table = <T,>({ columns, data, onTdClick }: ITableProps<T>): JSX.Element => {
    function onItemClick(item: T, event: React.MouseEvent<HTMLTableCellElement, MouseEvent>): void {
        if (!onTdClick) return;

        onTdClick(item);

        // const target = event.target as Element;
        // let hasActive = target.className.includes("ui_table_tbody_td");

        // if (hasActive) {
        //     onTdClick(item);
        // }
    }

    return (
        <table className="ui_table">
            <thead className="ui_table_thead">
                <tr>
                    {columns.map((column) => {
                        return (
                            <th key={column.key} style={{ width: column.width }} data-testid="table_thead">
                                {column.title}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody className="ui_table_tbody">
                {data.map((row: T, rowIndex: number) => (
                    <tr data-testid="dashboard_project" className={`${onTdClick ? "hover_active" : ""}`} key={rowIndex}>
                        {columns.map((column) => (
                            <td className="ui_table_tbody_td" onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => onItemClick(row, event)} key={column.key}>
                                {column.render ? column.render(row) : (row as any)[column.key]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
